body{
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #FFCD8E;
    font-family: 'Yeseva One', serif;
    font-family: 'Arimo', sans-serif;
    
}


ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}

li {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

a {
    text-decoration: none;
}

.container {
    width: 1140px;
    margin: auto;
}
.d_f{
    display: flex;
}
.jc_sb{
    justify-content: space-between;
}
.ai_c{
    align-items: center;
}
.jc_se{
    justify-content: space-evenly;
}





header{
    margin: 0 auto;
}
.header-home{
    height: 70px;
    background: #92C2BA;
}
.header-container{
    height: 70px;
}
.menu-section1_MPRR{
    color: #1F4C6A;
    font-family: Yeseva One;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.active-link{
    color: #FFF;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #1F4C6A;
    border-radius: 29px;
    background: #1F4C6A;
    width: 83px;
    height: 25px;
    padding: 5px 20px;
}
.menu-section2{
    width: 450px;
}
.passive-link{
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid transparent;
    border-radius: 29px;
    width: 83px;
    height: 25px;
    padding: 5px 20px;
}
.passive-link:hover{
    border: 1px solid #1F4C6A;
    border-radius: 29px;
    width: 83px;
    height: 25px;
    padding: 5px 20px;
    transition: all 0.3s ease;
}
.passive-link-region{
    color: #FFFFFF;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid transparent;
    border-radius: 29px;
    width: 83px;
    height: 25px;
    padding: 5px 20px;
}
.passive-link-region:hover{
    border: 1px solid #FFFFFF;
    border-radius: 29px;
    width: 83px;
    height: 25px;
    padding: 5px 20px;
    transition: all 0.3s ease;
}





.section-info-home{
    height: 415px;
    background: #92C2BA;
}
.map{
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    background: url(img/map2.svg);
    width: 680px;
    height: 360px; 
}
.title{
    width: 460px;
    height: 415px;
    position: relative;
}
.title_MPRR{
    text-align: center;
    position: absolute;
    
    top: 20%;
    left: 35%;

    color: #1F4C6A;
    font-family: Yeseva One;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.title_description1{
    text-align: center;
    position: absolute;
    
    top: 45%;
    left: 23%;

    color: #000;
    font-family: Arimo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.title_description2{
    text-align: center;
    position: absolute;
    
    top: 50%;
    left: 40%;

    color: #000;
    font-family: Arimo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




.projectionnamecolum3{
    width: 165px;
    height: 73px;
    background: #92C2BA;
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.projectionnamecolum4{
    width: 117px;
    height: 73px;
    border-radius: 0px 5px 0px 0px;
    background: #E0E0E0;
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-right: 1px solid #FFCD8E;
    border-radius: 0px 5px 0px 0px;
}
.projectioncolumn3{
    width: 166px;
    height: 50px;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #fff;
    box-shadow: 0 7px 2px -2px rgba(0, 0, 0, 0.56);
}
.projectioncolumn4{
    width: 117px;
    height: 50px;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #fff;
    border-radius: 0 15px 15px 0;
    box-shadow: 0 7px 2px -2px rgba(0, 0, 0, 0.56);
}
.name_overall{
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
table{
    border-spacing: 0px 10px;
    border-collapse: separate;
    margin: auto;
}
.namecolum1{
    width: 118px;
    height: 73px;
    border-radius: 5px 0px 0px 0px;
    background: #92C2BA;
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.namecolum2{
    width: 433px;
    height: 73px;
    background: #E0E0E0;
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.namecolum3{
    width: 165px;
    height: 73px;
    background: #92C2BA;
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-right: 1px solid #FFCD8E;
    border-radius: 0px 5px 0px 0px;
}
.namecolum4{
    width: 117px;
    height: 73px;
    border-radius: 0px 5px 0px 0px;
    background: #E0E0E0;
    color: #1F4C6A;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.column1{
    width: 118px;
    height: 50px;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #fff;
    border-radius: 15px 0 0 15px;
    width: 118px;
    height: 50px;
    background: rgba(255, 255, 255, 0);
    box-shadow: 0 7px 2px -2px rgba(0, 0, 0, 0.56);
}
.column2{
    width: 433px;
    height: 50px;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    background: rgba(255, 255, 255, 0);
    color: white;  
    border: 1px solid #fff;
    box-shadow: 0 7px 2px -2px rgba(0, 0, 0, 0.56);
}
.column3{
    width: 166px;
    height: 50px;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: 1px solid #fff;
    border-radius: 0 15px 15px 0;
    box-shadow: 0 7px 2px -2px rgba(0, 0, 0, 0.56);
}
.column4{
    width: 117px;
    height: 50px;
    font-family: Arimo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    box-shadow: 0 7px 2px -2px rgba(0, 0, 0, 0.56);
}
.region1{
    background: url(img/reg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region2{
    background: url(img/reg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region3{
    background: url(img/reg3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region4{
    background: url(img/reg4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region5{
    background: url(img/reg5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region6{
    background: url(img/reg6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region7{
    background: url(img/reg7.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region8{
    background: url(img/reg8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region9{
    background: url(img/reg9.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region10{
    background: url(img/reg10.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region11{
    background: url(img/reg11.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region12{
    background: url(img/reg12.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region13{
    background: url(img/reg13.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region14{
    background: url(img/reg14.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region15{
    background: url(img/reg15.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region16{
    background: url(img/reg16.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region17{
    background: url(img/reg17.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region18{
    background: url(img/reg18.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region19{
    background: url(img/reg19.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region20{
    background: url(img/reg20.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region21{
    background: url(img/reg21.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region22{
    background: url(img/reg22.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region23{
    background: url(img/reg23.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region24{
    background: url(img/reg24.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region25{
    background: url(img/reg25.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region26{
    background: url(img/reg26.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region27{
    background: url(img/reg27.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region28{
    background: url(img/reg28.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region29{
    background: url(img/reg29.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region30{
    background: url(img/reg30.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region31{
    background: url(img/reg31.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region32{
    background: url(img/reg32.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region33{
    background: url(img/reg33.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region34{
    background: url(img/reg34.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region35{
    background: url(img/reg35.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region36{
    background: url(img/reg36.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region37{
    background: url(img/reg37.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region38{
    background: url(img/reg38.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region39{
    background: url(img/reg39.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region40{
    background: url(img/reg40.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region41{
    background: url(img/reg41.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region42{
    background: url(img/reg42.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region43{
    background: url(img/reg43.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region44{
    background: url(img/reg44.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region45{
    background: url(img/reg45.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region46{
    background: url(img/reg46.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region47{
    background: url(img/reg47.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region48{
    background: url(img/reg48.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region49{
    background: url(img/reg49.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region50{
    background: url(img/reg50.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region51{
    background: url(img/reg51.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region52{
    background: url(img/reg52.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region53{
    background: url(img/reg53.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region54{
    background: url(img/reg54.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region55{
    background: url(img/reg55.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region56{
    background: url(img/reg56.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region57{
    background: url(img/reg57.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region58{
    background: url(img/reg58.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region59{
    background: url(img/reg59.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region60{
    background: url(img/reg60.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region61{
    background: url(img/reg61.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region62{
    background: url(img/reg62.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region63{
    background: url(img/reg63.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region64{
    background: url(img/reg64.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region65{
    background: url(img/reg65.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region66{
    background: url(img/reg66.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region67{
    background: url(img/reg67.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region68{
    background: url(img/reg68.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region69{
    background: url(img/reg69.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region70{
    background: url(img/reg70.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region71{
    background: url(img/reg71.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region72{
    background: url(img/reg72.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region73{
    background: url(img/reg73.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region74{
    background: url(img/reg74.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region75{
    background: url(img/reg75.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region76{
    background: url(img/reg76.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region77{
    background: url(img/reg77.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region78{
    background: url(img/reg78.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region79{
    background: url(img/reg79.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region80{
    background: url(img/reg80.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region81{
    background: url(img/reg81.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.region82{
    background: url(img/reg82.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection1{
    background: url(img/crit1.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection2{
    background: url(img/crit2.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection3{
    background: url(img/crit3.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection4{
    background: url(img/crit4.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection5{
    background: url(img/crit5.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection6{
    background: url(img/crit6.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection7{
    background: url(img/crit7.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection8{
    background: url(img/crit8.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection9{
    background: url(img/crit9.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection10{
    background: url(img/crit10.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection11{
    background: url(img/crit11.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection12{
    background: url(img/crit12.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection13{
    background: url(img/crit13.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection14{
    background: url(img/crit14.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection15{
    background: url(img/crit15.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection16{
    background: url(img/crit16.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.projection17{
    background: url(img/crit17.png);
    background-repeat: no-repeat;
    background-size: cover;
}

/* 
.odd{
    background: white;
}
.bor{
    border-right: 1px solid #FFCD8E;
}
.even{
    background: #E0E0E0;
} */