.jc_sa{
    justify-content: space-around;
}

.jc_c{
    justify-content: center;
}

.ji_c{
    justify-items: center;
}

.ac_c{
    align-content: center;
}

.d_g{
    display: grid;
}

.header-home{
    background: #FFCD8E;
}


main{
    width: 1140px;
    margin: auto;
}

.section_header{
    padding: 50px 0;
    font-family: 'Yeseva One', serif;
}

.text_info{
    width: 607px;

    font-family: Arimo;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text_info span{
    font-family: Yeseva One;
    color: #1F4C6A;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


h1{
    margin: 0;
    padding: 0;
    text-align: center;
    color: #1F4C6A;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.info_name p {
    margin-top: 15px;
    color: #000;
    font-family: Arimo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.info_name {
    padding-right: 108px;
}

.section_info{
    background-color: white;
    padding: 60px 0;
}

.atributs{
    color: #000;
    width: 300px;
    
    text-align: center;
    font-family: Arimo;
    font-size: 16px;
    font-weight: 400;
}

.atributs strong{
    color: #1F4C6A;
    font-family: Yeseva One;
    font-size: 17px;

    padding-bottom: 5px;
    border-bottom: 1px solid black;
}

.icon-info{
    padding-bottom: 8px;
}

.atributs p{
    height: 85px;
}

h2{
    margin: auto;
    color: #1F4C6A;
    font-family: Yeseva One;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 450px;

    border-bottom: 2px solid #1F4C6A;
    padding-bottom: 15px;
    margin-bottom: 40px;
}

h3{
    text-align: center;
    width: 30px;
    height: 30px;
    margin: 0;
    color: #FFCD8E;
    font-family: Yeseva One;
    font-size: 20px;
    font-weight: 400;
    border: 2px solid #FFCD8E;
    border-radius: 70%;
}


.interactive_globe{
    background-color: #92C2BA;
    padding: 30px 0;
}

.reference_part{
    background-color: #92C2BA;
    padding: 30px 0;
}

.globe-item__right img{
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.globe-item{
    display: grid;
    justify-items: end;
}
.globe-item__right .globe-item{
    display: grid;
    justify-items: start;
}
.globe-item{
    position: relative;
}

.globe-item p{
    margin: 0;
    color: #000;
    text-align: center;
    font-family: Arimo;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;

    
    top: 39%;
    left: 26%;
}

.globe-item__third p{
    top: 37%;
    left: 29%;
}

.globe-item__right p{
    top: 36%;
    left: 21%;
}

.criterion-circle{
    position: relative;
    width: 550px;
    height: 550px;
}


.circle-item{
    position: absolute;
    top: 45%;
    left: 43.8%;

    height: 59px;


    margin: 0;
    padding: 0;
    transition: 0.5s;
    background-color: #FFCD8E;
    border: 5px solid #FFCD8E;
    border-radius: 50%;
}

.non-active:nth-child(17):hover {
    transform: scale(1.3);
    
}

.circle-item:nth-child(1) {
    transform: rotate(270deg) translate(180px) rotate(-270deg);
}
.non-active:nth-child(1):hover {
    transform: rotate(270deg) translate(180px) rotate(-270deg) scale(1.3);
}

.circle-item:nth-child(2) {
    transform: rotate(292.5deg) translate(180px) rotate(-292.5deg);
}
.non-active:nth-child(2):hover {
    transform: rotate(292.5deg) translate(180px) rotate(-292.5deg) scale(1.3);
}

.circle-item:nth-child(3) {
    transform: rotate(315deg) translate(180px) rotate(-315deg);
}
.non-active:nth-child(3):hover {
    transform: rotate(315deg) translate(180px) rotate(-315deg) scale(1.3);
    
}

.circle-item:nth-child(4) {
    transform: rotate(337.5deg) translate(180px) rotate(-337.5deg);
}
.non-active:nth-child(4):hover {
    transform: rotate(337.5deg) translate(180px) rotate(-337.5deg) scale(1.3);
    
}

.circle-item:nth-child(5) {
    transform: rotate(0deg) translate(180px) rotate(0deg);
}
.non-active:nth-child(5):hover {
    transform: rotate(0deg) translate(180px) rotate(0deg) scale(1.3);
    
}


.circle-item:nth-child(6) {
    transform: rotate(22.5deg) translate(180px) rotate(-22.5deg);
}
.non-active:nth-child(6):hover {
    transform: rotate(22.5deg) translate(180px) rotate(-22.5deg) scale(1.3);

}


.circle-item:nth-child(7) {
    transform: rotate(45deg) translate(180px) rotate(-45deg);
}
.non-active:nth-child(7):hover {
    transform: rotate(45deg) translate(180px) rotate(-45deg) scale(1.3);
    
}


.circle-item:nth-child(8) {
    transform: rotate(67.5deg) translate(180px) rotate(-67.5deg);
}
.non-active:nth-child(8):hover {
    transform: rotate(67.5deg) translate(180px) rotate(-67.5deg) scale(1.3);
    
}


.circle-item:nth-child(9) {
    transform: rotate(90deg) translate(180px) rotate(-90deg);
}
.non-active:nth-child(9):hover {
    transform: rotate(90deg) translate(180px) rotate(-90deg) scale(1.3);
    
}


.circle-item:nth-child(10) {
    transform: rotate(112.5deg) translate(180px) rotate(-112.5deg);
}
.non-active:nth-child(10):hover {
    transform: rotate(112.5deg) translate(180px) rotate(-112.5deg) scale(1.3);
    
}


.circle-item:nth-child(11) {
    transform: rotate(135deg) translate(180px) rotate(-135deg);
}
.non-active:nth-child(11):hover {
    transform: rotate(135deg) translate(180px) rotate(-135deg) scale(1.3);
    
}


.circle-item:nth-child(12) {
    transform: rotate(157.5deg) translate(180px) rotate(-157.5deg);
}
.non-active:nth-child(12):hover {
    transform: rotate(157.5deg) translate(180px) rotate(-157.5deg) scale(1.3);
    
}


.circle-item:nth-child(13) {
    transform: rotate(180deg) translate(180px) rotate(-180deg);
}
.non-active:nth-child(13):hover {
    transform: rotate(180deg) translate(180px) rotate(-180deg) scale(1.3);
    
}


.circle-item:nth-child(14) {
    transform: rotate(202.5deg) translate(180px) rotate(-202.5deg);
}
.non-active:nth-child(14):hover {
    transform: rotate(202.5deg) translate(180px) rotate(-202.5deg) scale(1.3);
    
}


.circle-item:nth-child(15) {
    transform: rotate(225deg) translate(180px) rotate(-225deg);
}
.non-active:nth-child(15):hover {
    transform: rotate(225deg) translate(180px) rotate(-225deg) scale(1.3);
    
}

.circle-item:nth-child(16) {
    transform: rotate(247.5deg) translate(180px) rotate(-247.5deg);
}
.non-active:nth-child(16):hover {
    transform: rotate(247.5deg) translate(180px) rotate(-247.5deg) scale(1.3);
}

.circle-item:hover{
    margin: 0;
    border: 5px solid white;
    
    transition: 0.5s ease-in;
}

.pad-80{
    margin-top: 80px;
}

.reference-list__item p{
    margin: 0;
    color: #000;
    font-family: Arimo;
    font-size: 14px;
    font-weight: 700;

    text-align: center;
    width: 624px;
}

.reference-list__item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-item__text{
    display: flex;
    align-items: center;
    height: 94.8px;
    border: 2px solid white;
    border-left: none;
}
